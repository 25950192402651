import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  offenseCategories: {
    marginBottom: 25,
  },
  vspacer10: {
    height: 10,
  },
}));

export default function ReportDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Accept match"
        aria-describedby="Accept match"
      >
        <DialogTitle id="alert-dialog-title">
          Report player SmokePhantom
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Report a player for behavior that negatively impacted your game.
          </DialogContentText>

          <div className={classes.offenseCategories}>
            <FormControlLabel
              control={<Checkbox name="cheating" color="primary" />}
              className={classes.offenseCategory}
              label="Cheating"
            />

            <FormControlLabel
              control={<Checkbox name="griefing" color="primary" />}
              className={classes.offenseCategory}
              label="Griefing"
            />

            <FormControlLabel
              control={<Checkbox name="smurfing" color="primary" />}
              className={classes.offenseCategory}
              label="Smurfing"
            />

            <FormControlLabel
              control={<Checkbox name="abusive_comms" color="primary" />}
              className={classes.offenseCategory}
              label="Abusive Communication"
            />

            <FormControlLabel
              control={<Checkbox name="not_team_player" color="primary" />}
              className={classes.offenseCategory}
              label="Not a team player"
            />

            <FormControlLabel
              control={<Checkbox name="player_not_vocal" color="primary" />}
              className={classes.offenseCategory}
              label="Non communicative player"
            />
          </div>

          <TextField
            id="player-report-remarks"
            label="Remarks"
            style={{ width: "100%" }}
            inputProps={{ style: { height: 145 } }}
            multiline
            rows={4}
            defaultValue=""
            variant="outlined"
          />
        </DialogContent>
        <DialogActions style={{ paddingBottom: 20 }}>
          <Button onClick={handleClose} color="default" variant="contained">
            Dismiss
          </Button>
          <Button
            color="primary"
            autoFocus
            variant="contained"
            style={{ marginRight: 15 }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
