import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  smallSubTitle: {
    fontSize: "12pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  regularLink: {
    color: "#666",
  },
  "@media (min-width: 1024px)": {
    root: {
      width: 800,
    },
    para: {
      width: "85%",
      margin: "15px auto",
    },
  },
});

class PrivacyPolicyPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            Privacy Policy for Ouro
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            Last Updated: 20th of August, 2024
          </Typography>

          <Typography component={"p"} className={classes.para}>
            At Ouro, your privacy is our priority. This Privacy Policy explains
            how we collect, use, and protect your personal information when you
            use our matchmaking service and interact with our website. By using
            Ouro, you agree to the practices described in this policy.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            1. Information We Collect
          </Typography>

          <Typography component={"p"} className={classes.para}>
            We collect the following types of information to provide and improve
            our services:
            <ul>
              <li>
                Personal Information: When you register for an account, we may
                collect personal details such as your username, email address,
                and any other information you provide.
              </li>
              <li>
                Gameplay Data: We collect data related to your gaming activities
                on our platform, such as match history, in-game statistics, and
                behavioral patterns to enhance matchmaking fairness and
                accuracy.
              </li>
              <li>
                Technical Data: We collect technical information, such as your
                IP address, device type, browser type, and operating system, to
                ensure the security and functionality of our service.
              </li>
              <li>
                Communication Data: We may collect information from
                communications between you and Ouro, including customer support
                inquiries, feedback, and other correspondence.
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            2. How We Use Your Information
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <Typography className={classes.para}>
              Ouro uses your information for the following purposes:
            </Typography>

            <Typography className={classes.para}>
              <ul>
                <li>
                  Service Provision: To operate and maintain the matchmaking
                  service, including managing your account, facilitating
                  matches, and providing customer support.
                </li>
                <li>
                  Service Improvement: To analyze gameplay data and feedback,
                  enabling us to refine our algorithms, enhance user experience,
                  and create a fairer gaming environment.
                </li>
                <li>
                  Security: To detect and prevent fraudulent activities,
                  cheating, and other forms of abuse on our platform.
                </li>
                <li>
                  Communication: To send you important updates, notifications,
                  and information related to your account or our services.
                </li>
                <li>
                  Legal Compliance: To comply with legal obligations and respond
                  to lawful requests from authorities.
                </li>
              </ul>
            </Typography>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            3. How We Share Your Information
          </Typography>

          <Typography component={"p"} className={classes.para}>
            We do not sell, trade, or rent your personal information to third
            parties. However, we may share your information with:
            <ul>
              <li>
                <strong>Service Providers:</strong> Trusted third-party
                companies that help us operate our service, such as hosting
                providers, data analysts, and customer support platforms. These
                providers are obligated to maintain the confidentiality of your
                information.
              </li>
              <li>
                <strong>Legal Authorities:</strong> We may disclose your
                information if required by law or to protect our rights,
                property, or the safety of our users.
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            4. Cookies and Tracking Technologies
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro uses cookies and similar technologies to enhance your
            experience on our website. Cookies are small data files stored on
            your device that help us remember your preferences, understand how
            you interact with our service, and provide personalized content. You
            can manage your cookie preferences through your browser settings.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            5. Data Security
          </Typography>

          <Typography component={"p"} className={classes.para}>
            We take the security of your data seriously and implement a variety
            of measures to protect it from unauthorized access, alteration,
            disclosure, or destruction. However, no method of transmission over
            the internet or electronic storage is completely secure, and we
            cannot guarantee absolute security.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            6. Your Rights
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <Typography component={"p"} className={classes.para}>
              You have the following rights regarding your personal information:
            </Typography>
            <Typography component={"p"} className={classes.para}>
              <ul>
                <li>
                  <strong>Access:</strong> You can request a copy of the
                  personal information we hold about you.
                </li>
                <li>
                  <strong>Correction:</strong> You can ask us to correct or
                  update inaccurate or incomplete information.
                </li>
                <li>
                  <strong>Deletion:</strong> You can request the deletion of
                  your account and associated data, subject to certain legal
                  obligations.
                </li>
                <li>
                  <strong>Opt-Out:</strong> You can opt-out of receiving
                  marketing communications from us by following the unsubscribe
                  instructions in those messages.
                </li>
              </ul>
              To exercise these rights, please contact us at ouro.matchmaking AT
              gmail DOT com
            </Typography>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            7. Children’s Privacy
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro is not intended for children under the age of 13, and we do not
            knowingly collect personal information from children under this age.
            If we become aware that we have inadvertently collected such
            information, we will take steps to delete it as soon as possible.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            8. Changes to This Privacy Policy
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <Typography component={"p"} className={classes.para}>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We will notify you
              of any significant changes by posting the new policy on our
              website and updating the “Last Updated” date.
            </Typography>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            9. Contact Us
          </Typography>

          <Typography component={"p"} className={classes.para}>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            Ouro Support
          </Typography>

          <div className={classes.para}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <img
                      style={{ height: 20 }}
                      src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/Gmail_Logo_64px.png"
                    />
                  </td>
                  <td>
                    <a
                      className={classes.regularLink}
                      target="_blank"
                      href="ouro.support@gmail.com"
                    >
                      <Typography>ouro.support@gmail.com</Typography>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      style={{ height: 20 }}
                      src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/discord.png"
                    />
                  </td>
                  <td>
                    <a
                      className={classes.regularLink}
                      href="https://discord.gg/Ygx7AYmT"
                      target="_blank"
                    >
                      <Typography>Discord</Typography>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Paper>
      </div>
    );
  }
}

PrivacyPolicyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicyPage);
