import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
    listStyle: "none",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  para: {
    display: "list-item",
    margin: "15px 8px",
  },
  smallSubTitle: {
    fontSize: "12pt",
    fontWeight: 525,
  },
  regularLink: {
    color: "#666",
  },
  "@media (min-width: 1024px)": {
    root: {
      width: 800,
    },
    para: {
      width: "85%",
      margin: "15px auto",
    },
  },
});

class TermsOfUsePage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            Terms of Service for Ouro
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            Last Updated: 22th of August, 2024
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Welcome to Ouro, a matchmaking service designed to enhance your
            gaming experience in Counter-Strike. By accessing or using our
            platform, you agree to comply with and be bound by the following
            Terms of Service. Please read these terms carefully. If you do not
            agree with any part of these terms, you should not use Ouro.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            1. Acceptance of Terms
          </Typography>

          <Typography component={"p"} className={classes.para}>
            By creating an account or using Ouro’s services, you acknowledge
            that you have read, understood, and agree to be bound by these Terms
            of Service and our Privacy Policy.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            2. Eligibility
          </Typography>

          <Typography component={"p"} className={classes.para}>
            To use Ouro, you must be at least 13 years of age. By using our
            service, you represent and warrant that you meet this age
            requirement and that you have the legal capacity to enter into this
            agreement.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            3. Account Registration
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <ul>
              <li>
                <strong>Google Account Requirement:</strong> Account creation on
                Ouro currently requires a Google account (Gmail). You agree to
                provide a valid Google account during the registration process.
              </li>
              <li>
                <strong>Human Verification:</strong> Registering an account on
                Ouro does not guarantee that you will be able to queue for
                matchmaking. All accounts are subject to human verification to
                ensure they meet our matchmaking requirements. This verification
                process is designed to maintain the integrity and fairness of
                our platform.
              </li>
              <li>
                <strong>Accuracy:</strong> When you register for an account, you
                agree to provide accurate, current, and complete information.
                You are responsible for maintaining the accuracy of this
                information throughout your use of Ouro.
              </li>
              <li>
                <strong>Security:</strong> You are responsible for safeguarding
                your account credentials. You agree not to share your account
                details with others and to notify us immediately of any
                unauthorized use of your account.
              </li>
              <li>
                <strong>Account Activation:</strong> To activate your account,
                you are required to submit a personal passport or ID. This
                measure is in place to ensure that all users are legitimate and
                to maintain a safe, fair, and secure gaming environment.
              </li>
              <li>
                <strong>Termination:</strong>
                &nbsp; We reserve the right to suspend or terminate your account
                at our discretion if we believe you have violated these Terms of
                Service or engaged in activities that harm the Ouro community.
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            4. Use of the Service
          </Typography>

          <Typography
            component={"p"}
            className={classes.para}
            style={{ listStyle: "none" }}
          >
            <ul style={{ listStyle: "none" }}>
              <li>
                <Typography component={"p"} className={classes.para}>
                  <strong>Personal Use:</strong> Ouro is intended for your
                  personal, non-commercial use. You agree not to use the service
                  for any unlawful or unauthorized purposes.
                </Typography>
              </li>
              <li>
                <Typography component={"p"} className={classes.para}>
                  <strong>Prohibited Activities:</strong> You agree not to
                  engage in activities that disrupt or harm the experience of
                  other users, including but not limited to:
                  <ul>
                    <li>
                      Cheating, hacking, or using unauthorized third-party
                      software.
                    </li>
                    <li>Harassing, bullying, or engaging in toxic behavior.</li>
                    <li>Exploiting bugs or glitches in the service.</li>
                    <li>
                      Impersonating another user or attempting to gain
                      unauthorized access to accounts.
                    </li>
                  </ul>
                </Typography>
              </li>
              <li>
                <Typography component={"p"} className={classes.para}>
                  <strong>Community Standards:</strong> Ouro is a
                  community-driven platform. You agree to treat others with
                  respect and adhere to our community guidelines, which prohibit
                  offensive, defamatory, or otherwise inappropriate behavior.
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            5. Account Violations and Disciplinary Actions
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <ul>
              <li>
                <strong>Cooldown Periods:</strong> If you are caught violating
                our community guidelines or engaging in prohibited activities as
                mentioned throughout this document, you will receive a strike,
                resulting in a cooldown period during which you will be unable
                to participate in matchmaking. You will be provided with a
                reason for each cooldown.
              </li>
              <li>
                <strong>Three-Strike Rule:</strong> After receiving three
                strikes, your account will be permanently banned from Ouro, and
                you will no longer be able to access or use the platform. This
                decision is final and not subject to appeal.
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            6. Intellectual Property
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <ul style={{ listStyle: "none" }}>
              <li>
                <Typography component={"p"} className={classes.para}>
                  <strong>Ownership:</strong> All content, features, and
                  functionality on Ouro, including but not limited to software,
                  text, images, and logos, are the intellectual property of Ouro
                  or its licensors and are protected by copyright, trademark,
                  and other intellectual property laws.
                </Typography>
              </li>
              <li>
                <Typography component={"p"} className={classes.para}>
                  <strong>License:</strong> We grant you a limited,
                  non-exclusive, non-transferable license to use the service for
                  your personal use in accordance with these Terms of Service.
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            7. User-Generated Content
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <ul>
              <li>
                <strong>Responsibility:</strong> You are solely responsible for
                any content you create, post, or share on Ouro, including chat
                messages, forum posts, and feedback.
              </li>
              <li>
                <strong>Rights:</strong> By submitting content to Ouro, you
                grant us a worldwide, non-exclusive, royalty-free, perpetual
                license to use, modify, reproduce, and distribute your content
                in connection with the operation of the service.
              </li>
              <li>
                <strong>Removal:</strong> We reserve the right to remove any
                content that violates these Terms of Service or our community
                guidelines.
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            8. Payment and Refunds
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <Typography component={"p"} className={classes.para}>
              <ul>
                <li>
                  <strong>Payments:</strong> Some features of Ouro may require
                  payment. You agree to provide accurate payment information and
                  authorize us to charge the applicable fees.
                </li>
                <li>
                  <strong>Refunds:</strong> All payments made through Ouro are
                  non-refundable, except as required by law or as otherwise
                  stated in specific terms for a particular service.
                </li>
              </ul>
            </Typography>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            9. Disclaimers
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <ul>
              <li>
                <strong>No Warranty:</strong> Ouro is provided on an “as is” and
                “as available” basis. We make no warranties or representations
                about the accuracy, reliability, or availability of the service.
              </li>
              <li>
                <strong>Limitation of Liability:</strong> To the fullest extent
                permitted by law, Ouro and its affiliates shall not be liable
                for any indirect, incidental, consequential, or punitive damages
                arising from your use of the service.
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            10. Changes to the Service
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <Typography component={"p"} className={classes.para}>
              We may modify, suspend, or discontinue any part of Ouro at any
              time without notice. We are not liable to you or any third party
              for any changes to the service.
            </Typography>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            11. Changes to These Terms
          </Typography>

          <Typography component={"p"} className={classes.para}>
            We may update these Terms of Service from time to time to reflect
            changes in our practices or legal obligations. We will notify you of
            any significant changes by posting the updated terms on our website
            and updating the “Last Updated” date.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            12. Governing Law
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <Typography component={"p"} className={classes.para}>
              These Terms of Service are governed by and construed in accordance
              with the laws of Germany, without regard to its conflict of law
              principles. You agree to submit to the exclusive jurisdiction of
              the courts located in Germany for the resolution of any disputes
              arising out of or relating to these terms or the use of Ouro.
            </Typography>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            13. Contact Us
          </Typography>

          <Typography component={"p"} className={classes.para}>
            If you have any questions about these Terms of Service or our
            practices, please contact us at:
          </Typography>

          <div className={classes.para}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <img
                      style={{ height: 20 }}
                      src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/Gmail_Logo_64px.png"
                    />
                  </td>
                  <td>
                    <a
                      className={classes.regularLink}
                      target="_blank"
                      href="ouro.support@gmail.com"
                    >
                      <Typography>ouro.support@gmail.com</Typography>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      style={{ height: 20 }}
                      src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/discord.png"
                    />
                  </td>
                  <td>
                    <a
                      className={classes.regularLink}
                      href="https://discord.gg/Ygx7AYmT"
                      target="_blank"
                    >
                      <Typography>Discord</Typography>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Paper>
      </div>
    );
  }
}

TermsOfUsePage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsOfUsePage);
