export const LOADING = "LOADING";
export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export const PERFORM_LOGOUT = "PERFORM_LOGOUT";

export const ACCOUNT_FORM_FIELD_CHANGE = "ACCOUNT_FORM_FIELD_CHANGE";
export const STATS_UPDATED = "STATS_UPDATED";

export const ACCOUNT_UPDATE_DURING = "ACCOUNT_UPDATE_DURING";
export const ACCOUNT_UPDATE_ALERT = "ACCOUNT_UPDATE_ALERT";
export const ACCOUNT_UPDATE_ALERT_SNOOZE = "ACCOUNT_UPDATE_ALERT_SNOOZE";
export const ACCOUNT_UPDATE_COMPLETE = "ACCOUNT_UPDATE_COMPLETE";
export const ACCOUNT_SUCCESS_ALERT_SNOOZE = "ACCOUNT_SUCCESS_ALERT_SNOOZE";
