import { restoreCartFromCookieStorage } from "./utils/cart";

export default {
  catalog: {
    account: {},
    cart: restoreCartFromCookieStorage(),
    accountUpdateDuring: false,
    accountUpdateComplete: null,
    accountUpdateAlert: false,
    accountUpdateAlertText: "",
    user: null,
    performLogout: false,
    snackbar: null,
    toggleSnackbar: false,
  },
};
