import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TermsOfUsePage from "../components/terms-of-use-page";
import * as actions from "../redux/actions/catalogActions";

const mapStateToProps = (state, ownProps) => {
  return { catalog: state.catalogReducer };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUsePage);
