import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MapCard from "./MapCard";

import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import PublicIcon from "@material-ui/icons/Public";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import TodayIcon from "@material-ui/icons/Today";
import EqualizerIcon from "@material-ui/icons/Equalizer";

import MatchHistoryTable from "./MatchHistoryTable";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 0,

    marginLeft: "auto",
    marginRight: "auto",

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  homepageRoot: {
    minHeight: 560,
    marginTop: 8,
  },
  button: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formGroup: {
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },

  lobbyContainer: {
    display: "flex",
    position: "relative",
    width: 870,
    margin: "0 auto",
  },
  avatar: {
    width: 120,
    height: 120,
    margin: "15px auto",
    position: "relative",
    right: 0,
  },
  lobbyPersonBox: {
    width: 214,
    height: 235,
    marginRight: 20,
    borderRadius: 10,
    border: "solid 1px #adadad",
  },
  inviteLinkBtn: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  matchmakingOptionsCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    margin: "35px auto",
    position: "relative",
    textAlign: "left",
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    minHeight: 200,
    width: 740,
  },
  matchmakingSectionTitle: {
    position: "absolute",
    top: -23,
    fontWeight: 525,
  },
  anim: {
    position: "absolute",
    top: 13,
    right: -35,
  },
  statsCont: {
    position: "absolute",
    border: "solid 1px #b5b1b3",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: 8,
    padding: 16,
    top: 0,
    left: 250,
    width: 400,
    height: 204,
  },
  statField: { display: "flex", width: 400 },
  statIcon: { color: "#666666" },
  statTitle: { fontSize: 14, marginLeft: 8, fontWeight: 500, color: "#666666" },
  statValue: { fontSize: 14, marginLeft: 8, display: "flex" },
  profileFlag: { height: 24, display: "flex" },
  vspacer10: {
    height: 10,
  },
  steamProfileLinkButton: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  faceitProfileLinkButton: {
    position: "absolute",
    top: 57,
    right: 6,
  },
  steamIcon: {
    height: 48,
  },
  faceitIcon: {
    height: 32,
    width: 32,
    background: "#ffffff",
    padding: 5,
    borderRadius: 8,
    border: "solid 1px #666666",
  },
  matchHistoryTable: {
    width: 859,
  },
  "@media (min-width: 1024px)": {
    homepageRoot: {
      width: 1000,
      margin: "0 auto",
    },
    formGroup: {
      display: "flex",
      justifyContent: "center",
    },
    lobbyFrame: {
      margin: "20px auto",
      textAlign: "center",
      fontSize: "14pt",
      position: "relative",
      paddingTop: 49,
      minHeight: 500,
      width: 1000,
    },
    homeContainer: {
      display: "none",
    },
  },
});

function createData({ matchId, kills, adr, score, outcome, map, humanDate }) {
  return {
    matchId,
    kills,
    adr,
    score,
    outcome,
    map,
    humanDate,
  };
}

class ProfilePage extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {}

  validateForm = (e) => {
    e.preventDefault();
  };

  render() {
    const { classes, actions, catalog } = this.props;
    const { user } = catalog;

    const rows = [
      createData({
        matchId: "7978a80d-bf04-4d1a-9831-6edefec5b71b",
        kills: 14,
        adr: 83.4,
        score: "8-13",
        outcome: "Win",
        map: "Mirage",
        humanDate: "13 July, 2024",
        classes,
      }),
      createData({
        matchId: "7978a80d-bf04-4d1a-9831-6edefec5b71b",
        kills: 14,
        adr: 83.4,
        score: "5-13",
        outcome: "Loss",
        map: "Mirage",
        humanDate: "13 July, 2024",
        classes,
      }),
      createData({
        matchId: "7978a80d-bf04-4d1a-9831-6edefec5b71b",
        kills: 14,
        adr: 83.4,
        score: "13-11",
        outcome: "Win",
        map: "Ancient",
        humanDate: "13 July, 2024",
        classes,
      }),
      createData({
        matchId: "7978a80d-bf04-4d1a-9831-6edefec5b71b",
        kills: 14,
        adr: 83.4,
        score: "13-6",
        outcome: "Win",
        map: "Vertigo",
        humanDate: "13 July, 2024",
        classes,
      }),
      createData({
        matchId: "7978a80d-bf04-4d1a-9831-6edefec5b71b",
        kills: 14,
        adr: 83.4,
        score: "8-13",
        outcome: "Loss",
        map: "Nuke",
        humanDate: "13 July, 2024",
        classes,
      }),
    ];

    return (
      <div className={classes.homepageRoot}>
        <Paper className={classes.lobbyFrame}>
          {!user && <Typography>Please wait...</Typography>}

          {user && (
            <>
              <div className={classes.lobbyContainer}>
                <Paper
                  elevation={3}
                  className={classes.lobbyPersonBox}
                  style={{ border: "solid 2px #FF7500" }}
                >
                  <Typography>chiarezza</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>

                <div className={classes.statsCont}>
                  <div className={classes.statField} style={{ marginTop: 14 }}>
                    <div className={classes.statIcon}>
                      <PublicIcon />
                    </div>
                    <div className={classes.statTitle}>
                      <Typography>Country:</Typography>
                    </div>
                    <div className={classes.statValue}>
                      <img
                        className={classes.profileFlag}
                        src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                      />
                      <Typography style={{ marginLeft: 8 }}>Israel</Typography>
                    </div>
                  </div>

                  <div className={classes.statField}>
                    <div className={classes.statIcon}>
                      <TodayIcon />
                    </div>
                    <div className={classes.statTitle}>
                      <Typography>Joined:</Typography>
                    </div>
                    <div className={classes.statValue}>
                      <Typography>12 May, 2024</Typography>
                    </div>
                  </div>

                  <div className={classes.vspacer10} />

                  <div className={classes.statField}>
                    <div className={classes.statIcon}>
                      <EmojiEventsIcon />
                    </div>
                    <div className={classes.statTitle}>
                      <Typography>Matches:</Typography>
                    </div>
                    <div className={classes.statValue}>
                      <Typography>840</Typography>
                    </div>
                  </div>

                  <div className={classes.vspacer10} />

                  <div className={classes.statField}>
                    <div className={classes.statIcon}>
                      <EmojiEventsIcon />
                    </div>
                    <div className={classes.statTitle}>
                      <Typography>Wins/Losses:</Typography>
                    </div>
                    <div className={classes.statValue}>
                      <Typography>W: 383 / L:457</Typography>
                    </div>
                  </div>

                  <div className={classes.statField}>
                    <div className={classes.statIcon}>
                      <EqualizerIcon />
                    </div>
                    <div className={classes.statTitle}>
                      <Typography>Winrate %:</Typography>
                    </div>
                    <div className={classes.statValue}>
                      <Typography>45%</Typography>
                    </div>
                  </div>

                  <a
                    className={classes.steamProfileLinkButton}
                    href="http://www.google.com/"
                    target="_blank"
                  >
                    <img
                      className={classes.steamIcon}
                      src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/steam-icon-14889.png"
                    />
                  </a>

                  <a
                    className={classes.faceitProfileLinkButton}
                    href="http://www.google.com/"
                    target="_blank"
                  >
                    <img
                      className={classes.faceitIcon}
                      src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/faceit-seeklogo.svg"
                    />
                  </a>
                </div>
              </div>

              <div
                className={classes.matchmakingOptionsCont}
                style={{ width: 870 }}
              >
                <Typography className={classes.matchmakingSectionTitle}>
                  Stats per map
                </Typography>

                <MapCard name="Mirage" />
                <MapCard name="Inferno" />
                <MapCard name="Nuke" />
                <MapCard name="Anubis" />
                <MapCard name="Vertigo" />
                <MapCard name="Ancient" />
                <MapCard name="Dust II" style={{ marginRight: 0 }} />
              </div>

              <div
                className={classes.matchmakingOptionsCont}
                style={{ width: 870 }}
              >
                <Typography className={classes.matchmakingSectionTitle}>
                  Last 25 matches
                </Typography>

                <MatchHistoryTable
                  rows={rows}
                  className={classes.matchHistoryTable}
                />
              </div>

              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
            </>
          )}
        </Paper>
      </div>
    );
  }
}

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfilePage);
