/* eslint-disable import/no-named-as-default */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import utils from "./../../universal/utils";
import ChatIcon from "@material-ui/icons/Chat";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";

import PersonIcon from "@material-ui/icons/Person";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ContactUsIcon from "@material-ui/icons/MailOutline";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

// import { getCartItemsCount } from "./../../redux/reducers/utils/cart";
import { Typography } from "@material-ui/core";

import { GoogleLogin } from "@react-oauth/google";

const GOOGLE_TOKEN_LS_KEY = "ouroGoogleToken";

const hebrewLabels = {
  shoppingCartCTA: "לעגלת קניות",
  mobileMenu: {
    home: "דף הבית",
    customerService: "שירות לקוחות",
    directSales: "שיטת המכירה הישירה",
    installPoints: "נקודות הרכבה",
    qanda: "שאלות ותשובות",
    aboutUs: "אודות ספורטישופ",
    contactUs: "צור קשר",
    testimonials: "מה אומרים עלינו",
  },
  footer: {
    privacyPolicy: "מדיניות פרטיות",
    termsOfUse: "תנאי השימוש",
    serviceMail: "service@sportyshop.co.il",
    servicePhone: "054&ndash;9200911",
  },
};

const basePath = utils.getEdgeBasePath();

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#ffffff",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    width: "100%",
    borderBottom: "solid 1px #dcdcdc",
  },
  menuNavBarDesktop: {
    display: "none",
  },
  cartIcon: {
    color: "#545454",
    position: "absolute",
    left: 16,
  },
  accessibilityIcon: {
    color: "#545454",
    position: "absolute",
    left: 55,
  },
  phoneIcon: {
    color: "#545454",
    position: "absolute",
    left: 50,
  },
  cartEmpty: {
    color: "#545454",
    position: "absolute",
    left: 16,
  },
  menuButton: {
    color: "#545454",
    position: "absolute",
    left: -93,
    top: -5,
  },
  logo: {},
  root: {
    display: "flex",
    justifyContent: "center",
  },
  betaText: {
    position: "absolute",
    color: "#666666",
    fontSize: 12,
    left: 72,
    top: 31,
  },
  eloMenuCont: {
    display: "inline-flex",
    marginRight: 14,
    color: "#999999",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
  },
  "@media (min-width: 1024px)": {
    toolbar: {
      width: 1000,
      margin: "auto",
    },
    corona: {
      height: 65,
    },
    appBar: {
      top: 0,
    },
    michelinPromoPre: {
      fontFamily: "Noto Sans Hebrew, sans-serif",
      color: "#ffffff",
      float: "left",
      position: "relative",
      left: 5,
      top: 0,
      fontSize: 21,
    },
    daysRange: {
      fontFamily: "Montserrat, sans-serif",
      color: "#ffe721",
      float: "right",
      position: "relative",
      right: 18,
      top: 24,
      fontSize: 25,
    },
    days: {
      fontFamily: "Montserrat, sans-serif",
      color: "#ffffff",
      float: "right",
      position: "relative",
      right: -9,
      top: 24,
      fontSize: 25,
    },
    menuNavToolBar: {
      width: 1000,
      margin: "auto",
    },
    logoutButtonCont: {
      position: "absolute",
      right: 0,
    },
    phoneIcon: {
      display: "none",
    },
    navBarButton: {
      color: "#fefefe",
      fontSize: "12pt",
    },
    logoutButton: {
      position: "relative",
      bottom: 1,
    },
    menuNavBarDesktop: {
      background: "#0085B2",
      position: "fixed",
      display: "initial",
      width: "100%",
      borderBottom: "solid 1px #555656",
      top: 66,
    },
    avatar: {
      top: -2,
      left: -48,
      position: "absolute",
    },
    avatarName: {
      marginRight: 15,
    },
    logo: {
      position: "absolute",
      top: 16,
      left: 0,
    },
    rightIcon: {
      marginRight: 8,
    },
    accessibilityIcon: {
      left: "initial",
      right: 55,
    },
    cartIcon: {
      left: "initial",
      right: 16,
    },
  },

  list: {
    width: 250,
  },
  logoImg: {
    height: 33,
  },
  link: {
    textDecoration: "none",
  },
}));

export default function AppMenuBar({
  isAuthenticated,
  googleResponse,
  actions,
  onFailure,
  user = {},
  logout,
}) {
  const classes = useStyles();

  React.useEffect(() => {
    actions.getTrackingVisitorId();
  }, [user]);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const googleTokenInLocalStorage =
    typeof localStorage !== "undefined" &&
    localStorage.getItem(GOOGLE_TOKEN_LS_KEY) &&
    localStorage.getItem(GOOGLE_TOKEN_LS_KEY).length > 0;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <a className={classes.logo} href="/">
            <img
              alt="Sportyshop"
              className={classes.logoImg}
              src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/init-logo.png"
            />
          </a>
          <Typography className={classes.betaText}>beta</Typography>

          {isAuthenticated ? (
            <React.Fragment>
              <div className={classes.logoutButtonCont}>
                <IconButton
                  className={classes.menuButton}
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <MoreVertIcon />
                </IconButton>
                <Avatar
                  className={classes.avatar}
                  alt={user.name}
                  src={`${basePath}/api/v1/media/avatar/dedee024-3e71-45d1-a21d-5013ed70d1b4`}
                />
                <Button className={classes.avatarName} variant="outlined">
                  {user.name}
                </Button>

                <span className={classes.eloMenuCont}>
                  <Typography>1293</Typography>
                  <BubbleChartIcon />
                </span>

                <Button
                  variant="contained"
                  color="default"
                  onClick={logout}
                  className={classes.logoutButton}
                >
                  Logout
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <>
              <div style={{ position: "absolute", top: 12, right: 0 }}>
                <GoogleLogin
                  width={188}
                  onSuccess={googleResponse}
                  onFailure={onFailure}
                  auto_select
                  useOneTap
                />
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{ top: 7, zIndex: 10000 }}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      to="/lobby"
                      component={Link}
                      onClick={handleClose}
                    >
                      Lobby
                    </MenuItem>
                    <MenuItem
                      to="/account"
                      component={Link}
                      onClick={handleClose}
                    >
                      My account
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </div>
  );
}

AppMenuBar.propTypes = {
  children: PropTypes.element,
  isAuthenticated: PropTypes.bool.isRequired,
  autoLoginInProgress: PropTypes.bool.isRequired,
  googleResponse: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object,
  classes: PropTypes.object,
};
