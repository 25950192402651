import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function MatchHistoryTable({ rows }) {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      style={{ border: "solid 1px #cecece", width: 859 }}
    >
      <Table className={classes.table}>
        <TableHead style={{ background: "rgb(229 229 229)" }}>
          <TableRow>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Outcome</TableCell>
            <TableCell align="center">Score</TableCell>
            <TableCell align="center">ADR</TableCell>
            <TableCell align="center">Kills</TableCell>
            <TableCell align="center">Map</TableCell>
            <TableCell align="center">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.matchId}>
              <TableCell align="center">{row.humanDate}</TableCell>
              <TableCell align="center">{row.outcome}</TableCell>
              <TableCell align="center">{row.score}</TableCell>
              <TableCell align="center">{row.adr}</TableCell>
              <TableCell align="center">{row.kills}</TableCell>
              <TableCell align="center">{row.map}</TableCell>
              <TableCell align="center">
                <Button
                  to={`/match/${row.matchId}/scoreboard`}
                  component={Link}
                  variant="contained"
                >
                  View Match
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
