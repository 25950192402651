import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import MapCard from "./MapCard";
import ReportIcon from "@material-ui/icons/Report";
import IconButton from "@material-ui/core/IconButton";

import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ComputerIcon from "@material-ui/icons/Computer";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";

import ReportDialog from "./ReportDialog";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 0,

    marginLeft: "auto",
    marginRight: "auto",

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  homepageRoot: {
    minHeight: 560,
    marginTop: 8,
  },
  button: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formGroup: {
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  lobbyContainer: {
    display: "flex",
    padding: 16,
    position: "relative",
    border: "solid 1px #5e5d5d",
    borderRadius: 7,
    width: "75%",
    margin: "0 auto",
  },
  avatar: {
    width: 82,
    height: 82,
    margin: "15px auto",
    position: "relative",
    right: 1,
  },
  lobbyPersonBox: {
    width: 180,
    height: 190,
    marginRight: 20,
    position: "relative",
    borderRadius: 10,
    border: "solid 1px #adadad",
  },
  inviteLinkBtn: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  queueButton: {
    margin: "0 auto",
    fontSize: "24pt",
    padding: "4px 16px",
  },
  queueButtonCont: {
    margin: "0 auto",
    position: "relative",
    width: 150,
  },
  iconDuration: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  queueTimerCont: {
    borderRadius: 8,
    fontFamily: "Roboto",
    fontWeight: 525,
    fontSize: 20,
    paddingLeft: 30,
    width: 75,
    left: 144,
    top: 54,
    position: "absolute",
  },
  matchmakingOptionsCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    margin: "35px auto",
    position: "relative",
    textAlign: "left",
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    minHeight: 200,
    width: 555,
  },
  teamOneScore: {
    position: "absolute",
    fontFamily: "Roboto",
    color: "#2b2b2b",
    top: 0,
    right: 15,
    fontWeight: "bold",
    fontSize: 50,
  },
  teamTwoScore: {
    position: "absolute",
    fontFamily: "Roboto",
    color: "#2b2b2b",
    bottom: 0,
    right: 15,
    fontWeight: "bold",
    fontSize: 50,
  },
  matchmakingSectionTitle: {
    position: "absolute",
    top: -23,
    fontWeight: 525,
  },
  mmOptionRowCb: {
    height: 30,
  },
  minHoursPlayedField: {
    width: 100,
    fontSize: 11,
  },
  anim: {
    position: "absolute",
    top: 13,
    right: -35,
  },
  gameStatusCont: {
    position: "absolute",
    paddingLeft: 30,
    width: 200,
    left: 116,
    top: 142,
  },
  serverCont: {
    position: "absolute",
    paddingLeft: 30,
    width: 275,
    left: 144,
    top: 86,
  },
  serverIcon: {
    position: "absolute",
    top: 11,
    left: 0,
  },
  trophyIcon: {
    position: "absolute",
    right: 29,
    top: 101,
    height: 30,
    width: 30,
  },
  serverText: {
    position: "absolute",
    top: 11,
    left: 60,
  },
  serverAvatar: {
    position: "absolute",
    top: 11,
    width: 24,
    height: 24,
  },
  playerCountryFlag: {
    position: "absolute",
    bottom: 56,
    height: 19,
    right: 24,
  },
  serverIP: {
    position: "absolute",
    top: 2,
    left: 144,
  },
  reportButton: {
    position: "absolute",
    bottom: -10,
    left: -10,
  },
  controlCont: {
    position: "absolute",
    left: 254,
    bottom: 15,
  },
  gameStatusText: {
    fontWeight: 525,
    color: "#666666",
    fontSize: 15,
  },
  vspacer10: {
    height: 10,
  },
  "@media (min-width: 1024px)": {
    homepageRoot: {
      width: 1000,
      margin: "0 auto",
    },
    formGroup: {
      display: "flex",
      justifyContent: "center",
    },
    lobbyFrame: {
      margin: "20px auto",
      textAlign: "center",
      fontSize: "14pt",
      position: "relative",
      paddingTop: 49,
      minHeight: 500,
      width: 1000,
    },
    homeContainer: {
      display: "none",
    },
  },
});

class MatchPage extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {}

  validateForm = (e) => {
    e.preventDefault();
  };

  render() {
    const { classes, actions, catalog } = this.props;
    const { user } = catalog;

    return (
      <div className={classes.homepageRoot}>
        <Paper className={classes.lobbyFrame}>
          {!user && <Typography>Please wait...</Typography>}

          {user && (
            <>
              <div className={classes.lobbyContainer}>
                <Typography className={classes.matchmakingSectionTitle}>
                  Team_chiarezza
                </Typography>

                <ReportDialog />

                <Paper elevation={3} className={classes.lobbyPersonBox}>
                  <Typography>BulletBane</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                  <IconButton
                    aria-label="Report player"
                    className={classes.reportButton}
                  >
                    <ReportIcon />
                  </IconButton>
                </Paper>
                <Paper elevation={3} className={classes.lobbyPersonBox}>
                  <Typography>SmokePhantom</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
                <Paper
                  elevation={3}
                  className={classes.lobbyPersonBox}
                  style={{ border: "solid 2px #FF7500" }}
                >
                  <Typography>chiarezza</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
                <Paper elevation={3} className={classes.lobbyPersonBox}>
                  <Typography>NadeNinja</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
                <Paper
                  elevation={3}
                  className={classes.lobbyPersonBox}
                  style={{ marginRight: 0 }}
                >
                  <Typography>HeadshotHawk</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
              </div>

              <div className={classes.matchmakingOptionsCont}>
                <Typography className={classes.matchmakingSectionTitle}>
                  Match information
                </Typography>

                <MapCard name="Mirage" />

                <div className={classes.queueTimerCont}>
                  01:47
                  <AccessTimeIcon className={classes.iconDuration} />
                </div>

                <div className={classes.serverCont}>
                  <ComputerIcon className={classes.serverIcon} />

                  <Typography className={classes.serverText}>
                    Germany
                  </Typography>
                  <img
                    className={classes.serverAvatar}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748067_flag_germany.png"
                  />

                  <TextField
                    className={classes.serverIP}
                    InputProps={{
                      readOnly: true,
                      style: { color: "#454545" },
                    }}
                    size="small"
                    id="account-cs-server-ip"
                    label="Server"
                    variant="outlined"
                    defaultValue={"3.44.56.23:27015"}
                  />
                </div>

                <div className={classes.gameStatusCont}>
                  <Typography className={classes.gameStatusText}>
                    Waiting for players
                  </Typography>
                </div>

                <div className={classes.controlCont}>
                  <Button
                    to="/match/asdffasd/scoreboard"
                    component={Link}
                    variant="contained"
                  >
                    Scoreboard
                  </Button>
                </div>

                <Typography className={classes.teamOneScore}>13</Typography>
                <Typography className={classes.teamTwoScore}>11</Typography>
                <EmojiEventsIcon className={classes.trophyIcon} />
              </div>

              <div className={classes.lobbyContainer}>
                <Typography className={classes.matchmakingSectionTitle}>
                  Team_Hubert
                </Typography>
                <Paper elevation={3} className={classes.lobbyPersonBox}>
                  <Typography>ShadowStriker</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
                <Paper elevation={3} className={classes.lobbyPersonBox}>
                  <Typography>PixelPunisher</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
                <Paper elevation={3} className={classes.lobbyPersonBox}>
                  <Typography>Hubert</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
                <Paper elevation={3} className={classes.lobbyPersonBox}>
                  <Typography>FlashFury</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
                <Paper
                  elevation={3}
                  className={classes.lobbyPersonBox}
                  style={{ marginRight: 0 }}
                >
                  <Typography>chiarezza</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <img
                    className={classes.playerCountryFlag}
                    src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/country-icons/748026_flag_belgium.png"
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
              </div>

              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
            </>
          )}
        </Paper>
      </div>
    );
  }
}

MatchPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(MatchPage);
