import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Scoreboard({ rows }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{ background: "rgb(229 229 229)", border: 'solid 1px #cecece' }}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Player</TableCell>
            <TableCell align="center">Kills</TableCell>
            <TableCell align="center">Deaths</TableCell>
            <TableCell align="center">Assists</TableCell>
            <TableCell align="center">ADR</TableCell>
            <TableCell align="center">K/D Ratio</TableCell>
            <TableCell align="center">Headshots</TableCell>
            <TableCell align="center">HS %</TableCell>
            <TableCell align="center">MVPs</TableCell>
            <TableCell align="center">3K</TableCell>
            <TableCell align="center">4K</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.kills}</TableCell>
              <TableCell align="center">{row.deaths}</TableCell>
              <TableCell align="center">{row.assists}</TableCell>
              <TableCell align="center">{row.adr}</TableCell>
              <TableCell align="center">{row.kd}</TableCell>
              <TableCell align="center">{row.headshots}</TableCell>
              <TableCell align="center">{row.hs_perc}</TableCell>
              <TableCell align="center">{row.mvps}</TableCell>
              <TableCell align="center">{row.three_kills}</TableCell>
              <TableCell align="center">{row.four_kills}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
