import * as types from "../types/actionTypes";
import objectAssign from "object-assign";
import fetch from "node-fetch";
import utils from "./../../universal/utils";
import * as cookieStorage from "./common/cookieStorage";

const cStorage = cookieStorage.getCookieStorage();
const GOOGLE_TOKEN_LS_KEY = "ouroGoogleToken";

const basePath = utils.getEdgeBasePath();

export function setUser({ user }) {
  return {
    type: types.LOAD_USER_PROFILE,
    user,
  };
}

export function updateAccountFormField({ name, value }) {
  return {
    type: types.ACCOUNT_FORM_FIELD_CHANGE,
    name,
    value,
  };
}

export function dismissSuccessAccountAlert() {
  return {
    type: types.ACCOUNT_SUCCESS_ALERT_SNOOZE,
  };
}

export function setAccountUpdateAlert(text) {
  return {
    type: types.ACCOUNT_UPDATE_ALERT,
    text,
  };
}

export function dismissAccountUpdateAlert() {
  return {
    type: types.ACCOUNT_UPDATE_ALERT_SNOOZE,
  };
}

export function logout() {
  return {
    type: types.PERFORM_LOGOUT,
  };
}

export function collectMetric(metadata) {
  const stats = cStorage.getObject("stats");
  const { visitorId } = stats;

  return function (dispatch) {
    fetch(`${basePath}/collector/collect/${visitorId}`, {
      method: "POST",
      body: JSON.stringify(metadata),
      headers: { "Content-Type": "application/json" },
    }).then(function (apiResult) {
      return apiResult.json().then(function (json) {
        if (json.ok) {
          console.log("metric reported correctly");
        }
      });
    });
  };
}

export function getTrackingVisitorId() {
  const statsFromCookieStorage = cStorage.getObject("stats");

  if (statsFromCookieStorage !== null) {
    // User has an active visitorId, don't ask for one.
    return function (dispatch) {
      dispatch({
        type: types.STATS_UPDATED,
        stats: statsFromCookieStorage,
      });
    };
  }

  return function (dispatch) {
    fetch(`${basePath}/collector/visitor/id`).then(function (apiResult) {
      return apiResult.json().then(function (result) {
        const stats = {
          data: {
            likes: {
              products: [],
              shops: [],
            },
          },
          visitorId: result.visitorId,
        };

        dispatch({
          type: types.STATS_UPDATED,
          stats,
        });

        cStorage.setObject("stats", stats);
      });
    });
  };
}

export function submitAccountChanges(data) {
  const credential = localStorage && localStorage.getItem(GOOGLE_TOKEN_LS_KEY);
  if (!credential) {
    return;
  }

  return function (dispatch) {
    dispatch({
      type: types.ACCOUNT_UPDATE_DURING,
    });

    fetch(`${basePath}/api/v1/account`, {
      body: JSON.stringify({
        token: credential,
        data,
      }),
      cache: "no-cache",
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
      mode: "cors",
      redirect: "follow",
      referrer: "no-referrer",
    }).then(function (apiResult) {
      return apiResult.json().then(function (response) {
        return dispatch({
          type: types.ACCOUNT_UPDATE_COMPLETE,
          response,
        });
      });
    });
  };
}
