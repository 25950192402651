/* eslint-disable import/no-named-as-default */
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  mobile: {
    marginTop: 8,
  },
  desktop: {
    display: "none",
  },
  sectionTitle: {
    textDecoration: "none",
  },
  logoImg: {
    height: 120,
  },
  typoLink: {
    fontSize: 15,
    textDecoration: "none",
    padding: 8,
    // display: "block",
    color: "#3D3C3C",
  },
  paymentMethodLogo: {
    padding: 10,
    height: 48,
  },
  "@media (min-width: 1024px)": {
    mobile: {
      display: "none",
    },
    desktop: {
      display: "flex",
      minHeight: 200,
    },
    root: {
      width: "100%",
      minHeight: 200,
      marginTop: 20,
      backgroundColor: theme.palette.background.paper,
    },
    disclaimer: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
    inner: {
      width: 1000,
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
    footer: {
      marginTop: 35,
      padding: `${theme.spacing(2)}px 0`,
    },
  },
});

class CatalogFooter extends React.Component {
  render() {
    const { classes } = this.props;

    const footers = [
      {
        key: "sitemap",
        description: [
          {
            key: "about-link",
            text: "About us",
            url: "/about-us",
          },
          {
            key: "tou",
            text: "Terms",
            url: "/terms-of-use",
          },
          {
            key: "privacy-policy",
            text: "Privacy",
            url: "/privacy",
          },
          {
            key: "gdpr-statement",
            text: "GDPR",
            url: "/data-protection-policy-and-gdpr",
          },
        ],
      },
    ];

    return (
      <footer className={classNames(classes.footer, classes.layout)}>
        <div className={classes.inner}>
          <Grid
            container
            spacing={6}
            justifyContent="space-evenly"
            className={classes.desktop}
          >
            {footers.map((footer) => (
              <Grid item xs key={footer.key}>
                {footer.title && (
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.sectionTitle}
                  >
                    {footer.title}
                  </Typography>
                )}
                {footer.description &&
                  footer.description.map((item) => {
                    return (
                      <React.Fragment key={item.key}>
                        {item.external === undefined && (
                          <Typography
                            component={Link}
                            to={item.url}
                            variant="body2"
                            className={classes.typoLink}
                          >
                            {item.text}
                          </Typography>
                        )}

                        {item.external && item.external === true && (
                          <Typography
                            className={classes.typoLink}
                            component="a"
                            href={item.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {item.text}
                          </Typography>
                        )}
                      </React.Fragment>
                    );
                  })}
                {footer.content !== undefined && footer.content}
              </Grid>
            ))}
          </Grid>
        </div>

        <div className={classes.mobile}>
          <Grid container spacing={2} justifyContent="flex-start">
            {footers.map((footer) => (
              <Grid item sm={6} key={footer.key}>
                {footer.title && (
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.sectionTitle}
                  >
                    {footer.title}
                  </Typography>
                )}
                {footer.description &&
                  footer.description.map((item) => {
                    return (
                      <React.Fragment key={item.url}>
                        {item.external === undefined && (
                          <Typography
                            component={Link}
                            to={item.url}
                            variant="body2"
                            className={classes.typoLink}
                          >
                            {item.text}
                          </Typography>
                        )}

                        {item.external && item.external === true && (
                          <Typography
                            className={classes.typoLink}
                            component="a"
                            href={item.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {item.text}
                          </Typography>
                        )}
                      </React.Fragment>
                    );
                  })}
                {footer.content !== undefined && footer.content}
              </Grid>
            ))}
          </Grid>
        </div>
      </footer>
    );
  }
}

CatalogFooter.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(CatalogFooter);
