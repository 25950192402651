import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import ErrorIcon from "@material-ui/icons/Error";

import { makeStyles } from "@material-ui/core/styles";

const basePath =
  process.env.NODE_ENV === "production" ? "/edge" : "http://localhost:3010";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  statusBox: {
    marginTop: 4,
    marginLeft: 10,
    marginBottom: 6,
    border: "solid 1px #999999",
    width: "fit-content",
    paddingRight: 8,
    display: "flex",
    borderRadius: 5,
    padding: 4,
  },
  anim: {
    width: 24,
    height: 24,
  },
  statusText: {
    paddingLeft: 7,
    fontSize: 13,
    position: "relative",
    top: 2,
    color: "#999999",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const oneMegabyte = 1000000;

export default function UploadButton({
  name,
  onUploadComplete,
  acceptTypes = "image/*",
  fileSizeLimitInMB = 10,
}) {
  const classes = useStyles();
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > fileSizeLimitInMB * oneMegabyte) {
        setUploadStatus({
          state: "error",
          text: `File size limit is ${fileSizeLimitInMB}MB`,
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        setUploadStatus({ state: "load", text: "Uploading.." });
        const response = await fetch(`${basePath}/api/v1/upload`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const jsonResponse = await response.json();

          setUploadStatus({ state: "done", text: file.name });
          onUploadComplete({
            name,
            file,
            tmpFilename: jsonResponse.tmpFilename,
          });
        } else {
          setUploadStatus({ state: "error", text: "Upload failed" });
        }
      } catch (error) {
        setUploadStatus({
          state: "error",
          text: "Error during upload process",
        });
      }
    }
  };

  return (
    <>
      <input
        accept={acceptTypes}
        className={classes.input}
        id={name}
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor={name}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          startIcon={<CloudUploadIcon />}
        >
          Upload File
        </Button>
        {uploadStatus !== null && (
          <Paper className={classes.statusBox}>
            {uploadStatus.state === "load" && (
              <CircularProgress
                className={classes.anim}
                style={{ width: 24, height: 24 }}
                color="primary"
              />
            )}
            {uploadStatus.state === "done" && (
              <CloudDoneIcon
                className={classes.anim}
                style={{ width: 24, height: 24 }}
                color="primary"
              />
            )}
            {uploadStatus.state === "error" && (
              <ErrorIcon
                className={classes.anim}
                style={{ width: 24, height: 24 }}
                color="primary"
              />
            )}
            <Typography className={classes.statusText}>
              {uploadStatus.text}
            </Typography>
          </Paper>
        )}
      </label>
    </>
  );
}
