/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import { Redirect } from "react-router-dom";

import Menu from "./Menu";
import CatalogFooter from "./Footer-new";
import { GoogleOAuthProvider, googleLogout } from "@react-oauth/google";
import CookieConsent from "react-cookie-consent";
import { Typography } from "@material-ui/core";
const GOOGLE_TOKEN_LS_KEY = "ouroGoogleToken";
// import RTL from "./RTL";

const basePath =
  process.env.NODE_ENV === "production" ? "/edge" : "http://localhost:3010";

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (encodeURIComponent(value) || "") + expires + "; path=/";
}

const styles = {
  spacer: {
    height: 40,
  },
  "@media (min-width: 1024px)": {
    spacer: {
      height: 82,
    },
  },
};

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
      user: null,
      token: "",
      showRefreshWindow: false,
      autoLoginInProgress: false,
      googleAuthFailure: null,
    };
  }

  componentDidMount() {
    const googleTokenInLocalStorage =
      localStorage.getItem(GOOGLE_TOKEN_LS_KEY) &&
      localStorage.getItem(GOOGLE_TOKEN_LS_KEY).length > 0;
    const credential = localStorage.getItem(GOOGLE_TOKEN_LS_KEY);

    if (googleTokenInLocalStorage) {
      this.googleResponse({ credential, setLocalStorage: false });
    }
  }

  onFailure = () => {
    localStorage.removeItem(GOOGLE_TOKEN_LS_KEY);
    // this.setState({ googleAuthFailure: undefined });
  };

  googleResponse = ({ credential, setLocalStorage = true }) => {
    const options = {
      method: "POST",
      body: JSON.stringify({ credential }),
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      cache: "default",
    };

    if (setLocalStorage) {
      localStorage.setItem(GOOGLE_TOKEN_LS_KEY, credential);
    }

    fetch(`${basePath}/auth/google`, options)
      .then((r) => {
        return r.json().then((resp) => {
          let user = resp.user;
          let token = resp.user.token;
          setCookie("ouro-jwt", credential, 1);

          if (token) {
            this.props.actions.setUser({ user });
            this.setState({ isAuthenticated: true, user });
          }
        });
      })
      .catch(this.onFailure);
  };

  logout = () => {
    this.setState({
      isAuthenticated: false,
      token: "",
      user: null,
      autoLoginInProgress: false,
    });
    localStorage.removeItem(GOOGLE_TOKEN_LS_KEY);
    googleLogout();
    this.props.actions.logout();
  };

  render() {
    const { classes, catalog } = this.props;
    const { isAuthenticated, user, autoLoginInProgress } = this.state;
    const { performLogout } = catalog;

    const authenticatedByLS =
      typeof localStorage === "object" &&
      localStorage.getItem(GOOGLE_TOKEN_LS_KEY);

    const baseDescription =
      "Ouro is a new alternative matchmaking service for CS2";

    return (
      // <RTL>
      <div>
        <GoogleOAuthProvider clientId="1068129342176-704fo06p1eqtqp9me1o948btj290qsf5.apps.googleusercontent.com">
          <Menu
            actions={this.props.actions}
            catalog={this.props.catalog}
            logout={this.logout}
            autoLoginInProgress={autoLoginInProgress}
            isAuthenticated={isAuthenticated}
            googleResponse={this.googleResponse}
            user={user}
            onFailure={this.onFailure}
          />
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="Content-language" content="en" />
            <meta
              property="og:title"
              content="Ouro: The Competitive Cycle Begins Here - Coming soon!"
            />
            {/* <meta
            property="og:image"
            content="https://s3.eu-west-2.amazonaws.com/sportyshop/catalog/homepage/covers/cover.jpeg"
          /> */}
            <meta property="og:site_name" content="Ouro matchmaking for cs2" />
            <meta property="og:description" content={baseDescription} />
            <meta property="description" content={baseDescription} />

            <title>
              Ouro: The Competitive Cycle Begins Here - Coming soon!
            </title>
          </Helmet>

          <div className={classes.spacer}></div>

          {performLogout === true && <Redirect to={`/`} />}
          {!authenticatedByLS && <Redirect to={`/`} />}

          {this.props.children}
          <CatalogFooter />
          <CookieConsent
            location="bottom"
            buttonText={"ACCEPT"}
            cookieName="ouro-matchmaking-consent"
            style={{ background: "#2B373B" }}
            buttonStyle={{
              color: "#fefefe",
              padding: 8,
              position: "relative",
              top: 10,
              right: 5,
              fontWeight: 525,
              background: "#ff8f05",
              fontSize: "11pt",
              borderRadius: 5,
            }}
          >
            <Typography>
              {
                "We use cookies to enhance your browsing experience and analyze site traffic."
              }{" "}
              <br />
              {
                'By clicking "Accept" you consent to the use of cookies in accordance with our Cookie Policy. For more information, please read our Privacy Policy.'
              }
            </Typography>
          </CookieConsent>
        </GoogleOAuthProvider>
      </div>
      // </RTL>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  jsses: PropTypes.object,
  actions: PropTypes.object,
  catalog: PropTypes.object,
};

export default withStyles(styles)(App);
