import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import MapCard from "./MapCard";

import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CircularProgress from "@material-ui/core/CircularProgress";
import AcceptDialog from "./AcceptDialog";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 0,

    marginLeft: "auto",
    marginRight: "auto",

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  homepageRoot: {
    minHeight: 560,
    marginTop: 8,
  },
  button: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formGroup: {
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  lobbyContainer: {
    display: "flex",
    width: "75%",
    margin: "0 auto",
  },
  avatar: {
    width: 82,
    height: 82,
    margin: "15px auto",
    position: "relative",
    right: 1,
  },
  lobbyPersonBox: {
    width: 180,
    height: 190,
    marginRight: 20,
    borderRadius: 10,
    border: "solid 1px #adadad",
  },
  inviteLinkBtn: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  queueButton: {
    margin: "0 auto",
    fontSize: "24pt",
    padding: "4px 16px",
  },
  queueButtonCont: {
    margin: "0 auto",
    position: "relative",
    width: 150,
  },
  queueTimerCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    fontFamily: "Roboto",
    fontWeight: 525,
    fontSize: 28,
    width: 94,
    margin: "20px auto",
    padding: 16,
    position: "relative",
  },
  matchmakingOptionsCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    margin: "35px auto",
    position: "relative",
    textAlign: "left",
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    minHeight: 200,
    width: 740,
  },
  matchmakingSectionTitle: {
    position: "absolute",
    top: -23,
    fontWeight: 525,
  },
  mmOptionRowCb: {
    height: 30,
  },
  minHoursPlayedField: {
    width: 100,
    fontSize: 11,
  },
  anim: {
    position: "absolute",
    top: 13,
    right: -35,
  },
  vspacer10: {
    height: 10,
  },
  "@media (min-width: 1024px)": {
    homepageRoot: {
      width: 1000,
      margin: "0 auto",
    },
    formGroup: {
      display: "flex",
      justifyContent: "center",
    },
    lobbyFrame: {
      margin: "20px auto",
      textAlign: "center",
      fontSize: "14pt",
      position: "relative",
      paddingTop: 49,
      minHeight: 500,
      width: 1000,
    },
    homeContainer: {
      display: "none",
    },
  },
});

class LobbyPage extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {}

  validateForm = (e) => {
    e.preventDefault();
  };

  render() {
    const { classes, actions, catalog } = this.props;
    const { user } = catalog;

    return (
      <div className={classes.homepageRoot}>
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" component={Link} to="/account">
              ACCOUNT SETTINGS
            </Button>
          }
        >
          <AlertTitle>Account not activated yet</AlertTitle>
          Your Ouro account has some pending tasks for you to complete before
          you can queue for a match
        </Alert>

        <Paper className={classes.lobbyFrame}>
          {!user && <Typography>Please wait...</Typography>}

          {user && (
            <>
              {/* <AcceptDialog /> */}
              <div className={classes.lobbyContainer}>
                <Paper elevation={3} className={classes.lobbyPersonBox} />
                <Paper elevation={3} className={classes.lobbyPersonBox} />
                <Paper
                  elevation={3}
                  className={classes.lobbyPersonBox}
                  style={{ border: "solid 2px #FF7500" }}
                >
                  <Typography>chiarezza</Typography>
                  <Avatar
                    className={classes.avatar}
                    size="large"
                    src={user.picture}
                  />
                  <BubbleChartIcon />
                  <Typography>1293</Typography>
                </Paper>
                <Paper elevation={3} className={classes.lobbyPersonBox} />
                <Paper
                  elevation={3}
                  className={classes.lobbyPersonBox}
                  style={{ marginRight: 0 }}
                />
              </div>

              <Chip
                className={classes.inviteLinkBtn}
                avatar={<FileCopyIcon />}
                label="Copy invite link"
                clickable
                color="default"
              />

              <div
                className={classes.matchmakingOptionsCont}
                style={{ width: 870 }}
              >
                <Typography className={classes.matchmakingSectionTitle}>
                  Maps
                </Typography>

                <MapCard name="Mirage" />
                <MapCard name="Inferno" />
                <MapCard name="Nuke" />
                <MapCard name="Anubis" />
                <MapCard name="Vertigo" />
                <MapCard name="Ancient" />
                <MapCard name="Dust II" style={{ marginRight: 0 }} />
              </div>

              <div className={classes.queueButtonCont}>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.queueButton}
                  color="primary"
                >
                  play
                </Button>

                <CircularProgress className={classes.anim} color="secondary" />
              </div>

              <div className={classes.queueTimerCont}>01:47</div>

              <div className={classes.matchmakingOptionsCont}>
                <Typography className={classes.matchmakingSectionTitle}>
                  Matchmaking options
                </Typography>

                <FormControlLabel
                  control={<Checkbox name="above_hours" color="primary" />}
                  className={classes.mmOptionRowCb}
                  style={{ height: 40 }}
                  label="Match players above these hours"
                />
                <TextField
                  size="small"
                  className={classes.minHoursPlayedField}
                  label="Hours"
                  variant="outlined"
                  defaultValue={"1939.94"}
                />

                <div className={classes.vspacer10} />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="total_steam_cs_matches_won"
                      color="primary"
                    />
                  }
                  className={classes.mmOptionRowCb}
                  style={{ height: 40 }}
                  label="Minimum official Valve MM competitive matches played"
                />
                <TextField
                  size="small"
                  className={classes.minHoursPlayedField}
                  label="Matches"
                  variant="outlined"
                  defaultValue={"278"}
                />

                <div className={classes.vspacer10} />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="total_steam_cs_matches_min_winrate"
                      color="primary"
                    />
                  }
                  className={classes.mmOptionRowCb}
                  style={{ height: 40 }}
                  label={
                    <Typography>
                      Valve MM competitive <strong>min</strong> win rate %
                    </Typography>
                  }
                />
                <TextField
                  size="small"
                  className={classes.minHoursPlayedField}
                  label="Win rate %"
                  variant="outlined"
                  defaultValue={"45"}
                />

                <div className={classes.vspacer10} />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="total_steam_cs_matches_max_winrate"
                      color="primary"
                    />
                  }
                  className={classes.mmOptionRowCb}
                  style={{ height: 40 }}
                  label={
                    <Typography>
                      Valve MM competitive <strong>max</strong> win rate %
                    </Typography>
                  }
                />
                <TextField
                  size="small"
                  className={classes.minHoursPlayedField}
                  label="Win rate %"
                  variant="outlined"
                  defaultValue={"60"}
                />

                <div className={classes.vspacer10} />

                <FormControlLabel
                  control={
                    <Checkbox name="maxEloDifferenceInTeam" color="primary" />
                  }
                  className={classes.mmOptionRowCb}
                  style={{ height: 40 }}
                  label="Max allowed ELO difference among players"
                />
                <TextField
                  size="small"
                  className={classes.minHoursPlayedField}
                  label="ELO Diff"
                  variant="outlined"
                  defaultValue={"150"}
                />

                <div className={classes.vspacer10} />

                <FormControlLabel
                  control={<Checkbox name="min_player_age" color="primary" />}
                  className={classes.mmOptionRowCb}
                  style={{ height: 40 }}
                  label={
                    <Typography>Match players at or above this age</Typography>
                  }
                />
                <TextField
                  size="small"
                  className={classes.minHoursPlayedField}
                  label="Player age"
                  variant="outlined"
                  defaultValue={"18"}
                />

                <div className={classes.vspacer10} />

                <FormControlLabel
                  control={
                    <Checkbox name="player_has_inventory" color="primary" />
                  }
                  className={classes.mmOptionRowCb}
                  style={{ height: 40 }}
                  label={
                    <Typography>Match players with an inventory</Typography>
                  }
                />
                <div className={classes.vspacer10} />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="player_has_expensive_inventory"
                      color="primary"
                    />
                  }
                  className={classes.mmOptionRowCb}
                  style={{ height: 40 }}
                  label={
                    <Typography>
                      Match players with a considerable inventory{" "}
                      <strong>(above $500)</strong>
                    </Typography>
                  }
                />
              </div>
              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
            </>
          )}
        </Paper>
      </div>
    );
  }
}

LobbyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(LobbyPage);
