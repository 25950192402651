import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

const maps = {
  mirage: {
    url: "https://static.wikia.nocookie.net/cswikia/images/f/f5/De_mirage_cs2.png",
  },
  nuke: {
    url: "https://primagames.com/wp-content/uploads/2023/10/Nuke-Callouts-for-CS2.jpg?w=1200",
  },
  ancient: {
    url: "https://prosettings.net/cdn-cgi/image/dpr=1%2Cf=auto%2Cfit=cover%2Cgravity=auto%2Cheight=536%2Cq=85%2Cwidth=970/wp-content/uploads/ancient-1.jpg",
  },
  dust_ii: {
    url: "https://www.esports.net/wp-content/uploads/2023/10/Dust-2-CS2-1.jpg",
  },
  vertigo: {
    url: "https://pbs.twimg.com/media/F1RTa4NX0AMOVBl.jpg:large",
  },
  inferno: {
    url: "https://tradeit.gg/blog/wp-content/uploads/2023/09/CS2-Inferno-Callouts-8-1024x576.webp",
  },
  anubis: {
    url: "https://steamuserimages-a.akamaihd.net/ugc/2193876107321240926/4F3A2D9E8A51B390FC2B77407F9CBF0E60F555B9/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
  },
};

const useStyles = makeStyles({
  root: {
    width: 112,
    display: "inline-block",
    borderRadius: 7,
    border: "solid 1px #c4c4c4",
    marginRight: 10,
    position: "relative",
  },
  mapStat: {
    position: "absolute",
    fontSize: 10,
    fontWeight: 525,
    bottom: 6,
    left: 10,
  },
  media: {
    height: 150,
  },
});

export default function MapCard({ name }) {
  const classes = useStyles();

  if (!maps[name.toLowerCase().replace(" ", "_")]) {
    console.log("cannot find map ", name);
    return <></>;
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={maps[name.toLowerCase().replace(" ", "_")].url}
          title={name}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontSize: 16, position: 'relative', left: -6, }}
          >
            {name}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography className={classes.mapStat}>
            W: 57
            <br />
            L: 35
            <br />
            Avg ADR: 77.44
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
