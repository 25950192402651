import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import utils from "./../../universal/utils";

import objectAssign from "object-assign";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import UploadButton from "./UploadField";

import { Alert, AlertTitle } from "@material-ui/lab";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 0,

    marginLeft: "auto",
    marginRight: "auto",

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  homepageRoot: {
    minHeight: 560,
    marginTop: 8,
  },
  button: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formGroup: {
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  lobbyContainer: {
    display: "flex",
    width: "75%",
    margin: "0 auto",
  },
  avatar: {
    width: 82,
    height: 82,
    margin: "15px auto",
    position: "relative",
    right: 1,
  },
  lobbyPersonBox: {
    width: 180,
    height: 190,
    marginRight: 20,
    borderRadius: 10,
    border: "solid 1px #adadad",
  },
  inviteLinkBtn: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  queueButton: {
    margin: "0 auto",
    fontSize: "24pt",
    padding: "4px 16px",
  },
  queueButtonCont: {
    margin: "0 auto",
    position: "relative",
    width: 150,
  },
  queueTimerCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    fontFamily: "Roboto",
    fontWeight: 525,
    fontSize: 28,
    width: 94,
    margin: "20px auto",
    padding: 16,
    position: "relative",
  },
  matchmakingOptionsCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    margin: "35px auto",
    position: "relative",
    textAlign: "left",
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    minHeight: 200,
    width: 740,
  },
  matchmakingSectionTitle: {
    position: "absolute",
    top: -23,
    fontWeight: 525,
  },
  anim: {
    position: "absolute",
    top: 13,
    right: -35,
  },
  verificationBorder: {
    border: "solid 1px #477ab5",
    position: "relative",
    padding: 10,
    paddingTop: 0,
    paddingBottom: 24,
    borderRadius: 7,
  },
  vspacer10: {
    height: 10,
  },
  selfieExplanation: {
    position: "absolute",
    top: 48,
    left: 10,
  },
  updateAvatarBox: {
    border: "solid 1px #cecece",
    position: "relative",
    width: 250,
    minHeight: 79,
    borderRadius: 6,
  },
  updateAvatarLabel: {
    position: "absolute",
    top: -10,
    fontSize: 13,
    left: 10,
    color: "#8f8f8f",
    background: "#ffffff",
    paddingRight: 3,
    paddingLeft: 3,
  },
  existingAvatar: {
    position: "absolute",
    top: 5,
    right: 5,
    height: 68,
    width: 68,
  },
  textFieldRegular: {
    marginRight: 25,
  },
  accountStatusOrange: {
    color: "orange",
    fontWeight: "bold",
  },
  rejectionAlert: {
    marginTop: 7,
    width: "fit-content",
  },
  regularLink: {
    color: "#666",
  },
  buttonsCont: {
    marginBottom: 20,
  },
  accountInner: {
    marginLeft: 20,
  },
  documentUploadedNote: {
    position: "absolute",
    fontSize: 11,
    top: 7,
    right: 8,
  },
  loadingAnim: {
    color: "#003073",
  },
  docUploadedIcon: {
    position: "absolute",
    color: "#737272",
    height: 18,
    top: -2,
    left: -24,
  },
  "@media (min-width: 1024px)": {
    homepageRoot: {
      width: 1000,
      margin: "0 auto",
    },
    formGroup: {
      display: "flex",
      justifyContent: "center",
    },
    lobbyFrame: {
      margin: "20px auto",
      textAlign: "left",
      fontSize: "14pt",
      position: "relative",
      paddingTop: 27,
      minHeight: 500,
      width: 1000,
    },
    homeContainer: {
      display: "none",
    },
  },
});

const accountStatusColors = {
  pending: "#303030",
  verified: "#008f00",
  rejected: "#b31902",
};

const basePath = utils.getEdgeBasePath();
class AccountPage extends React.Component {
  constructor() {
    super();
    this.state = {
      showAlert: false,
      alertType: "warning",
      alertContent: undefined,
      loading: null,
    };
  }

  componentDidMount() {}

  handleFormChange = (e) => {
    if (e.target.name === "termsAccepted") {
      this.props.actions.updateAccountFormField({
        name: e.target.name,
        value: e.target.checked,
      });
    } else {
      this.props.actions.updateAccountFormField({
        name: e.target.name,
        value: e.target.value,
      });
    }
  };

  handleFileFieldChange = ({ file, name, tmpFilename }) => {
    this.props.actions.updateAccountFormField({
      name,
      value: {
        file: file.name,
        tmpFilename,
      },
    });
  };

  saveChanges = () => {
    const { user, account } = this.props.catalog;

    const {
      name,
      nickname = "",
      age = "",
      country = "",
      termsAccepted = false,
      steamId64 = "",
      steamProfileUrl = "",
      faceitProfileUrl = "",
      steamInventoryValue = "none",
    } = objectAssign({}, user, account);

    if (name === "") {
      this.props.actions.setAccountUpdateAlert(
        "Missing full name of the account holder"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if (nickname === "") {
      this.props.actions.setAccountUpdateAlert("Missing in-game nickname");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if (age === "") {
      this.props.actions.setAccountUpdateAlert("Missing your age");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if (country === "") {
      this.props.actions.setAccountUpdateAlert(
        "You did not fill in your country, please note the country should match your supplied document country"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if (!termsAccepted) {
      this.props.actions.setAccountUpdateAlert(
        "You have not accepted the terms of our platform, please be sure to read them before/after you do!"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if (steamId64 === "") {
      this.props.actions.setAccountUpdateAlert(
        "You didn't insert your SteamID 64 from your Steam account page"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if (steamProfileUrl === "") {
      this.props.actions.setAccountUpdateAlert("Missing Steam profile URL");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if (faceitProfileUrl === "") {
      this.props.actions.setAccountUpdateAlert("Missing FaceIT profile URL");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    const data = objectAssign({}, account, { userId: user.userId });

    this.props.actions.submitAccountChanges(data);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 24);
  };

  dismissSuccessAlert = () => {
    this.props.actions.dismissSuccessAccountAlert();
  };

  dismissAlert = () => {
    this.props.actions.dismissAccountUpdateAlert();
  };

  validateForm = (e) => {
    e.preventDefault();
  };

  render() {
    const { classes, catalog } = this.props;
    const {
      user,
      account,
      accountUpdateComplete = false,
      accountUpdateDuring = false,
      accountUpdateAlert,
      accountUpdateAlertText,
    } = catalog;

    const {
      name,
      nickname = "",
      age = "",
      country = "",
      termsAccepted = false,
      accountStatus = "pending",
      accountDisplayStatus = "PENDING VERIFICATION",
      steamId64 = "",
      steamProfileUrl = "",
      faceitProfileUrl = "",
      steamInventoryValue = "none",

      // Media
      passport = null,
      selfie_video = null,
      passportRejected = false,
      selfieVideoRejected = false,
    } = objectAssign({}, user, account);

    let passportUploaded = user && user.passport && user.passport.file;
    let selfieVideoUploaded =
      user && user.selfie_video && user.selfie_video.file;

    return (
      <>
        <div className={classes.homepageRoot}>
          {!accountUpdateAlert && accountUpdateDuring && (
            <Alert
              icon={
                <CircularProgress
                  className={classes.loadingAnim}
                  style={{ width: 20, height: 20 }}
                />
              }
              className={classes.alert}
              severity="info"
            >
              Please wait, saving your changes..
            </Alert>
          )}

          {!accountUpdateAlert &&
            !accountUpdateDuring &&
            accountUpdateComplete && (
              <Alert
                className={classes.alert}
                severity="success"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={this.dismissSuccessAlert}
                  >
                    Dismiss
                  </Button>
                }
              >
                Details updated successfully!
              </Alert>
            )}

          {!accountUpdateComplete && accountUpdateAlert && (
            <Alert
              className={classes.alert}
              severity={this.state.alertType}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={this.dismissAlert}
                >
                  Dismiss
                </Button>
              }
            >
              <AlertTitle>Some fields are missing!</AlertTitle>
              {accountUpdateAlertText}
            </Alert>
          )}

          <Paper className={classes.lobbyFrame}>
            {!user && <Typography>Please wait...</Typography>}

            {user && (
              <>
                <div className={classes.accountInner}>
                  <Typography component="h2">Account Settings</Typography>
                  <div className={classes.vspacer10} />
                  <div className={classes.vspacer10} />

                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                      className={classes.textFieldRegular}
                      error={name.length < 2}
                      id="account-full-name"
                      disabled={accountStatus === "verified"}
                      name="name"
                      label="Full name"
                      variant="outlined"
                      onChange={this.handleFormChange}
                      value={name}
                    />

                    <TextField
                      className={classes.textFieldRegular}
                      error={nickname.length < 2}
                      id="account-username"
                      name="nickname"
                      label="Nickname"
                      variant="outlined"
                      onChange={this.handleFormChange}
                      value={nickname}
                    />

                    <TextField
                      className={classes.textFieldRegular}
                      style={{ width: 120 }}
                      error={age.length < 2}
                      disabled={accountStatus === "verified"}
                      inputProps={{
                        type: "number",
                        readOnly: accountStatus === "verified",
                      }}
                      helperText="13 years min"
                      id="account-age"
                      label="Age"
                      name="age"
                      value={age}
                      onChange={this.handleFormChange}
                      variant="outlined"
                    />

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{ minWidth: 180 }}
                    >
                      <InputLabel id="account-country-label">
                        Country
                      </InputLabel>
                      <Select
                        id="account-country"
                        name="country"
                        disabled={accountStatus === "verified"}
                        error={country === ""}
                        onChange={this.handleFormChange}
                        value={country}
                        label="Country"
                      >
                        <MenuItem value="AT">Austria</MenuItem>
                        <MenuItem value="BE">Belgium</MenuItem>
                        <MenuItem value="BG">Bulgaria</MenuItem>
                        <MenuItem value="HR">Croatia</MenuItem>
                        <MenuItem value="CY">Cyprus</MenuItem>
                        <MenuItem value="CZ">Czech Republic</MenuItem>
                        <MenuItem value="DK">Denmark</MenuItem>
                        <MenuItem value="EE">Estonia</MenuItem>
                        <MenuItem value="FI">Finland</MenuItem>
                        <MenuItem value="FR">France</MenuItem>
                        <MenuItem value="DE">Germany</MenuItem>
                        <MenuItem value="GR">Greece</MenuItem>
                        <MenuItem value="HU">Hungary</MenuItem>
                        <MenuItem value="IE">Ireland</MenuItem>
                        <MenuItem value="IT">Italy</MenuItem>
                        <MenuItem value="LV">Latvia</MenuItem>
                        <MenuItem value="LT">Lithuania</MenuItem>
                        <MenuItem value="LU">Luxembourg</MenuItem>
                        <MenuItem value="MT">Malta</MenuItem>
                        <MenuItem value="NL">Netherlands</MenuItem>
                        <MenuItem value="PL">Poland</MenuItem>
                        <MenuItem value="PT">Portugal</MenuItem>
                        <MenuItem value="RO">Romania</MenuItem>
                        <MenuItem value="SK">Slovakia</MenuItem>
                        <MenuItem value="SI">Slovenia</MenuItem>
                        <MenuItem value="ES">Spain</MenuItem>
                        <MenuItem value="SE">Sweden</MenuItem>
                      </Select>
                    </FormControl>

                    <div className={classes.vspacer10} />
                    <div className={classes.vspacer10} />

                    <div className={classes.accountStatusCont}>
                      <TextField
                        className={classes.textFieldRegular}
                        InputProps={{
                          readOnly: true,
                          style: {
                            fontWeight: "bold",
                            color: accountStatusColors[accountStatus],
                          },
                        }}
                        style={{ width: 210 }}
                        id="account-status"
                        label="Account status"
                        variant="outlined"
                        value={accountDisplayStatus}
                      />

                      <div className={classes.vspacer10} />
                      <div className={classes.vspacer10} />
                      <div className={classes.vspacer10} />
                    </div>

                    {accountStatus !== "verified" && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={this.handleFormChange}
                            name="termsAccepted"
                            value="on"
                            checked={termsAccepted}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            I have read and agree to the terms of use for Ouro
                            Matchmaking
                          </Typography>
                        }
                      />
                    )}
                    <div className={classes.vspacer10} />
                    {accountStatus !== "verified" && (
                      <Button
                        component={Link}
                        to="/terms-of-use"
                        target="_blank"
                      >
                        TERMS OF USE
                      </Button>
                    )}

                    {accountStatus !== "verified" && (
                      <>
                        <div className={classes.vspacer10} />
                        <div className={classes.vspacer10} />
                        <FormControl className={classes.verificationBorder}>
                          <InputLabel style={{ position: "relative" }}>
                            Passport or Personal ID document
                          </InputLabel>

                          {!passportUploaded && (
                            <Typography
                              className={classes.documentUploadedNote}
                              style={{ color: "#ba0b0b" }}
                            >
                              <ErrorIcon
                                className={classes.docUploadedIcon}
                                style={{ color: "#ba0b0b" }}
                              />
                              Passport/ID not uploaded
                            </Typography>
                          )}

                          {passportUploaded && (
                            <Typography
                              className={classes.documentUploadedNote}
                            >
                              <CloudDoneIcon
                                className={classes.docUploadedIcon}
                              />
                              Uploaded: {passport.file}
                            </Typography>
                          )}
                          <div className={classes.vspacer10} />
                          <div className={classes.vspacer10} />
                          <div className={classes.vspacer10} />
                          <div className={classes.vspacer10} />
                          <UploadButton
                            name="passport"
                            acceptTypes="image/*,application/pdf"
                            fileSizeLimitInMB={20}
                            onUploadComplete={this.handleFileFieldChange}
                          />
                          <FormHelperText>
                            Passport is stored with encryption at rest complying
                            with all GDPR rules
                            <br />
                            Once human verification of your documents is done,
                            the document is removed from our storage
                          </FormHelperText>

                          {passportRejected && (
                            <Alert
                              severity="error"
                              className={classes.rejectionAlert}
                            >
                              <strong>Your document has been rejected!</strong>
                              <br />
                              Please upload a new document for verification.
                            </Alert>
                          )}
                        </FormControl>

                        <div className={classes.vspacer10} />
                        <div className={classes.vspacer10} />
                        <div className={classes.vspacer10} />

                        <FormControl
                          className={classes.verificationBorder}
                          style={{ width: 800 }}
                        >
                          <InputLabel style={{ position: "relative" }}>
                            Selfie video with Passport/ID
                          </InputLabel>
                          <Typography className={classes.selfieExplanation}>
                            Record a short selfie video saying the following
                            sentence while holding your ID card visible: <br />
                            <strong>
                              "My name is {user.name}, and I understand and
                              agree to follow Ouro matchmaking rules and
                              community guidelines, and I accept the
                              consequences of any violations."
                            </strong>
                          </Typography>

                          {!selfieVideoUploaded && (
                            <Typography
                              className={classes.documentUploadedNote}
                              style={{ color: "#ba0b0b" }}
                            >
                              <ErrorIcon
                                className={classes.docUploadedIcon}
                                style={{ color: "#ba0b0b" }}
                              />
                              Selfie video not uploaded
                            </Typography>
                          )}

                          {selfieVideoUploaded && (
                            <Typography
                              className={classes.documentUploadedNote}
                            >
                              <CloudDoneIcon
                                className={classes.docUploadedIcon}
                              />
                              Uploaded: {selfie_video.file}
                            </Typography>
                          )}

                          <div
                            className={classes.vspacer10}
                            style={{ height: 120 }}
                          />
                          <UploadButton
                            name="selfie_video"
                            fileSizeLimitInMB={150}
                            acceptTypes="video/*"
                            onUploadComplete={this.handleFileFieldChange}
                          />
                          <FormHelperText>
                            Once human verification of your video is done, the
                            video file will be removed from our storage
                          </FormHelperText>

                          {selfieVideoRejected && (
                            <Alert
                              severity="error"
                              className={classes.rejectionAlert}
                            >
                              <strong>Your video has been rejected!</strong>
                              <br />
                              Please upload a new selfie video for review
                            </Alert>
                          )}
                        </FormControl>

                        <div className={classes.vspacer10} />
                        <div className={classes.vspacer10} />
                        <div className={classes.vspacer10} />
                      </>
                    )}

                    <FormControl className={classes.updateAvatarBox}>
                      <Typography className={classes.updateAvatarLabel}>
                        Update avatar
                      </Typography>

                      <div className={classes.vspacer10} />
                      <UploadButton
                        name="avatar"
                        onUploadComplete={this.handleFileFieldChange}
                      />
                      <Avatar
                        src={`${basePath}/api/v1/media/avatar/dedee024-3e71-45d1-a21d-5013ed70d1b4`}
                        className={classes.existingAvatar}
                      />
                    </FormControl>

                    <div className={classes.vspacer10} />
                    <div className={classes.vspacer10} />

                    <FormControl>
                      <TextField
                        className={classes.textFieldRegular}
                        error={steamId64 === ""}
                        disabled={accountStatus === "verified"}
                        inputProps={{ type: "number" }}
                        id="account-steam64-id"
                        label="SteamID 64"
                        name="steamId64"
                        onChange={this.handleFormChange}
                        variant="outlined"
                        value={steamId64}
                      />
                      <FormHelperText id="component-helper-text">
                        Once your account is verified this cannot be changed
                        anymore <br />
                        You can find your Steam64 ID by navigating to your
                        &nbsp;{" "}
                        <a
                          className={classes.regularLink}
                          target="_blank"
                          href="https://store.steampowered.com/account/"
                        >
                          Steam account page
                        </a>{" "}
                        <br />
                        It will be under the title [Player name]'s account
                      </FormHelperText>
                    </FormControl>
                    <div className={classes.vspacer10} />
                    <div className={classes.vspacer10} />
                    <TextField
                      className={classes.textFieldRegular}
                      style={{ width: 450 }}
                      disabled={accountStatus === "verified"}
                      error={steamProfileUrl === ""}
                      id="account-steam-profile-url"
                      label="Steam Profile URL"
                      variant="outlined"
                      name="steamProfileUrl"
                      onChange={this.handleFormChange}
                      value={steamProfileUrl}
                    />
                    <div className={classes.vspacer10} />
                    <div className={classes.vspacer10} />

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{ minWidth: 180 }}
                    >
                      <InputLabel id="account-country-label">
                        Steam CS Inventory
                      </InputLabel>
                      <Select
                        id="account-steam-inventory"
                        name="steamInventoryValue"
                        value={steamInventoryValue}
                        error={steamInventoryValue === ""}
                        onChange={this.handleFormChange}
                        label="Steam CS Inventory"
                      >
                        <MenuItem value="none">None</MenuItem>
                        <MenuItem value="have">I have an inventory</MenuItem>
                        <MenuItem value="have_considerable">
                          I have a considerable inventory (above $500)
                        </MenuItem>
                      </Select>
                      <FormHelperText id="component-helper-text">
                        This is used for matchmaking filters
                      </FormHelperText>
                    </FormControl>
                    <div className={classes.vspacer10} />
                    <div className={classes.vspacer10} />
                    <FormControl>
                      <TextField
                        className={classes.textFieldRegular}
                        disabled={accountStatus === "verified"}
                        style={{ width: 450 }}
                        error={faceitProfileUrl === ""}
                        id="account-faceit-profile-url"
                        label="FaceIT Profile URL"
                        variant="outlined"
                        name="faceitProfileUrl"
                        onChange={this.handleFormChange}
                        value={faceitProfileUrl}
                      />
                      <FormHelperText id="component-helper-text">
                        Your ELO will be imported from FaceIT upon verification
                      </FormHelperText>
                    </FormControl>

                    <div className={classes.vspacer10} />
                    <div className={classes.vspacer10} />
                    <div className={classes.buttonsCont}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={accountUpdateDuring}
                        onClick={this.saveChanges}
                        style={{ marginRight: 20 }}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </Paper>
        </div>
      </>
    );
  }
}

AccountPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountPage);
