import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "14pt",
    fontWeight: 525,
  },
  smallSubTitle: {
    fontSize: "12pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  regularLink: {
    color: "#666",
  },
  "@media (min-width: 1024px)": {
    root: {
      width: 800,
    },
    para: {
      width: "85%",
      margin: "15px auto",
    },
  },
});

class GDPRPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            GDPR Compliance for Ouro
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            Last Updated: 21th of August, 2024
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro is committed to protecting your privacy and ensuring that your
            personal data is handled in compliance with the General Data
            Protection Regulation (GDPR). This page outlines your rights under
            the GDPR and explains how we collect, use, and protect your personal
            data.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            className={classes.smallSubTitle}
          >
            1. Data Controller
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro is a marketing name of: Sportyshop Sports Marketing (VAT ID:
            DE815666847), is the data controller responsible for your personal
            data. If you have any questions or concerns about how your data is
            handled, please contact us at:
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <strong>Ouro Support</strong>
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Email:{" "}
            <a
              className={classes.regularLink}
              href="mailto: ouro.matchmaking@gmail.com"
            >
              ouro.matchmaking@gmail.com
            </a>
          </Typography>
          <Typography component={"p"} className={classes.para}>
            <strong>Address:</strong> Beedstrasse 54, 40468, Düsseldorf, Germany
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            2. Personal Data We Collect
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro collects and processes the following categories of personal
            data:
            <ul>
              <li>
                Contact Information: When you create an account, we collect your
                email address associated with your Google account (Gmail).
              </li>
              <li>
                Identification Information: To activate your account, we may
                collect personal identification details, such as your passport
                or ID number.
              </li>
              <li>
                Gameplay Data: We collect data related to your gaming
                activities, such as match history, in-game statistics, and
                behavioral patterns.
              </li>
              <li>
                Technical Data: This includes your IP address, device type,
                browser type, and other technical information necessary for the
                operation and security of our service.
              </li>
              <li>
                Communication Data: Information from communications between you
                and Ouro, including customer support inquiries, feedback, and
                other interactions.
              </li>
            </ul>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            3. Legal Basis for Processing
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <Typography component={"p"} className={classes.para}>
              Ouro processes your personal data based on the following legal
              grounds:
              <ul>
                <li>
                  Performance of a Contract: We process your data to provide and
                  maintain our matchmaking service as per our Terms of Service.
                </li>
                <li>
                  Consent: We rely on your consent to process certain types of
                  personal data, such as when you voluntarily provide
                  identification documents for account verification.
                </li>
                <li>
                  Legitimate Interests: We process data to improve our services,
                  ensure security, prevent fraud, and protect the interests of
                  our users and our platform.
                </li>
                <li>
                  Legal Obligations: We may process your data to comply with
                  legal obligations, such as responding to lawful requests from
                  authorities.
                </li>
              </ul>
            </Typography>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            4. Your Rights Under the GDPR
          </Typography>

          <Typography component={"p"} className={classes.para}>
            As a user from the European Union, you have the following rights
            concerning your personal data:
            <ul>
              <li>
                Right to Access: You have the right to request a copy of the
                personal data we hold about you.
              </li>
              <li>
                Right to Rectification: You can request that we correct or
                update any inaccurate or incomplete personal data.
              </li>
              <li>
                Right to Erasure: You have the right to request the deletion of
                your personal data, subject to certain legal obligations.
              </li>
              <li>
                Right to Restrict Processing: You can request that we limit the
                processing of your data under certain circumstances.
              </li>
              <li>
                Right to Data Portability: You have the right to receive your
                personal data in a structured, commonly used, and
                machine-readable format and to transmit it to another data
                controller.
              </li>
              <li>
                Right to Object: You can object to the processing of your data
                for specific purposes, such as direct marketing or when we rely
                on legitimate interests as the basis for processing.
              </li>
              <li>
                Right to Withdraw Consent: If we rely on your consent for
                processing, you have the right to withdraw your consent at any
                time.
              </li>
            </ul>
            To exercise any of these rights, please contact us at{" "}
            <a
              className={classes.regularLink}
              href="mailto: ouro.matchmaking@gmail.com"
            >
              ouro.matchmaking@gmail.com
            </a>
            .
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            5. Data Retention
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro retains your personal data only as long as necessary to fulfill
            the purposes for which it was collected, including any legal,
            accounting, or reporting requirements. When your data is no longer
            needed, we will securely delete or anonymize it.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            6. Data Security
          </Typography>

          <Typography component={"p"} className={classes.para}>
            <Typography component={"p"} className={classes.para}>
              We implement appropriate technical and organizational measures to
              protect your personal data from unauthorized access, alteration,
              disclosure, or destruction. While we strive to use commercially
              acceptable means to protect your data, no method of transmission
              over the internet or electronic storage is 100% secure.
            </Typography>
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            7. International Data Transfers
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro may transfer your personal data to countries outside the
            European Economic Area (EEA) for processing. We ensure that any such
            transfers comply with applicable data protection laws, typically
            through the use of standard contractual clauses approved by the
            European Commission or other appropriate safeguards.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            8. Automated Decision-Making
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro does not engage in automated decision-making, including
            profiling, that has a legal or similarly significant effect on you
            without your explicit consent.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            9. Complaints
          </Typography>

          <Typography component={"p"} className={classes.para}>
            If you believe that your data protection rights have been violated,
            you have the right to lodge a complaint with a supervisory authority
            in the EU. We encourage you to contact us first to resolve any
            concerns.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            10. Changes to This GDPR Compliance Page
          </Typography>

          <Typography component={"p"} className={classes.para}>
            We may update this GDPR Compliance page from time to time to reflect
            changes in our practices or legal obligations. We will notify you of
            any significant changes by posting the updated page on our website
            and updating the “Last Updated” date.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            style={{ color: "#6e6e6e" }}
            className={classes.smallSubTitle}
          >
            11. Contact Us
          </Typography>

          <Typography component={"p"} className={classes.para}>
            For more information about our GDPR compliance or to exercise your
            rights, please contact us at:
          </Typography>

          <div className={classes.para}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <img
                      style={{ height: 20 }}
                      src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/Gmail_Logo_64px.png"
                    />
                  </td>
                  <td>
                    <a
                      className={classes.regularLink}
                      target="_blank"
                      href="ouro.support@gmail.com"
                    >
                      <Typography>ouro.support@gmail.com</Typography>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      style={{ height: 20 }}
                      src="https://ouro-public-assets.s3.eu-west-2.amazonaws.com/discord.png"
                    />
                  </td>
                  <td>
                    <a
                      className={classes.regularLink}
                      href="https://discord.gg/Ygx7AYmT"
                      target="_blank"
                    >
                      <Typography>Discord</Typography>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Paper>
      </div>
    );
  }
}

GDPRPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(GDPRPage);
