import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Scoreboard from "./scoreboard";

const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 0,

    marginLeft: "auto",
    marginRight: "auto",

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  homepageRoot: {
    minHeight: 560,
    marginTop: 8,
  },
  button: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formGroup: {
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  lobbyContainer: {
    display: "flex",
    padding: 16,
    position: "relative",
    border: "solid 1px #5e5d5d",
    borderRadius: 7,
    width: "75%",
    margin: "0 auto",
  },
  avatar: {
    width: 82,
    height: 82,
    margin: "15px auto",
    position: "relative",
    right: 1,
  },
  lobbyPersonBox: {
    width: 180,
    height: 190,
    marginRight: 20,
    position: "relative",
    borderRadius: 10,
    border: "solid 1px #adadad",
  },
  inviteLinkBtn: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  queueButton: {
    margin: "0 auto",
    fontSize: "24pt",
    padding: "4px 16px",
  },
  queueButtonCont: {
    margin: "0 auto",
    position: "relative",
    width: 150,
  },
  iconDuration: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  queueTimerCont: {
    borderRadius: 8,
    fontFamily: "Roboto",
    fontWeight: 525,
    fontSize: 20,
    paddingLeft: 30,
    width: 75,
    left: 144,
    top: 54,
    position: "absolute",
  },
  matchmakingOptionsCont: {
    border: "solid 1px #b5b1b3",
    borderRadius: 8,
    margin: "35px auto",
    position: "relative",
    textAlign: "left",
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    minHeight: 200,
    width: 555,
  },
  teamOneScore: {
    position: "absolute",
    fontFamily: "Roboto",
    color: "#2b2b2b",
    top: 0,
    right: 15,
    fontWeight: "bold",
    fontSize: 50,
  },
  teamTwoScore: {
    position: "absolute",
    fontFamily: "Roboto",
    color: "#2b2b2b",
    bottom: 0,
    right: 15,
    fontWeight: "bold",
    fontSize: 50,
  },
  matchmakingSectionTitle: {
    position: "absolute",
    top: -23,
    fontWeight: 525,
  },
  mmOptionRowCb: {
    height: 30,
  },
  minHoursPlayedField: {
    width: 100,
    fontSize: 11,
  },
  anim: {
    position: "absolute",
    top: 13,
    right: -35,
  },
  gameStatusCont: {
    position: "absolute",
    paddingLeft: 30,
    width: 200,
    left: 116,
    top: 142,
  },
  serverCont: {
    position: "absolute",
    paddingLeft: 30,
    width: 275,
    left: 144,
    top: 86,
  },
  serverIcon: {
    position: "absolute",
    top: 11,
    left: 0,
  },
  trophyIcon: {
    position: "absolute",
    right: 29,
    top: 101,
    height: 30,
    width: 30,
  },
  playerName: {
    position: "relative",
    bottom: 2,
    paddingLeft: 28,
  },
  serverText: {
    position: "absolute",
    top: 11,
    left: 60,
  },
  serverAvatar: {
    position: "absolute",
    top: 11,
    width: 24,
    height: 24,
  },
  scoreboard: {
    
  },
  playerCountryFlag: {
    position: "absolute",
    bottom: 56,
    height: 19,
    right: 24,
  },
  serverIP: {
    position: "absolute",
    top: 2,
    left: 144,
  },
  controlCont: {
    margin: "20px auto",
  },
  gameStatusText: {
    fontWeight: 525,
    color: "#666666",
    fontSize: 15,
  },
  vspacer10: {
    height: 10,
  },
  statsTableAvatar: {
    position: "absolute",
    width: 20,
    height: 20,
  },
  "@media (min-width: 1024px)": {
    homepageRoot: {
      width: 1000,
      margin: "0 auto",
    },
    formGroup: {
      display: "flex",
      justifyContent: "center",
    },
    lobbyFrame: {
      margin: "20px auto",
      textAlign: "center",
      fontSize: "14pt",
      position: "relative",
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 49,
      minHeight: 500,
      width: 1000,
    },
    homeContainer: {
      display: "none",
    },
  },
});

function createData({
  name,
  kills,
  deaths,
  assists,
  adr,
  kd,
  headshots,
  hs_perc,
  mvps,
  three_kills,
  four_kills,
  classes,
}) {
  return {
    name: (
      <div>
        <Avatar
          className={classes.statsTableAvatar}
          src="https://lh3.googleusercontent.com/a/ACg8ocJZ8hvcAfnucxWgOawUNFf1NwTCVi6VYHeD090aDUsa-qm5JlPahA=s96-c"
        />
        <Typography className={classes.playerName}>{name}</Typography>
      </div>
    ),
    kills,
    deaths,
    assists,
    adr,
    kd,
    headshots,
    hs_perc,
    mvps,
    three_kills,
    four_kills,
  };
}

class MatchScoreboardPage extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {}

  validateForm = (e) => {
    e.preventDefault();
  };

  render() {
    const { classes, actions, catalog } = this.props;
    const { user } = catalog;

    const rows = [
      createData({
        name: "chiarezza",
        kills: 14,
        deaths: 7,
        assists: 4,
        adr: 83.4,
        kd: 0.78,
        headshots: 9,
        hs_perc: "64%",
        mvps: 2,
        three_kills: 0,
        four_kills: 0,
        classes,
      }),

      createData({
        name: "hubert",
        kills: 14,
        deaths: 7,
        assists: 4,
        adr: 83.4,
        kd: 0.78,
        headshots: 9,
        hs_perc: "64%",
        mvps: 2,
        three_kills: 0,
        four_kills: 0,
        classes,
      }),
      createData({
        name: "von hades",
        kills: 14,
        deaths: 7,
        assists: 4,
        adr: 83.4,
        kd: 0.78,
        headshots: 9,
        hs_perc: "64%",
        mvps: 2,
        three_kills: 0,
        four_kills: 0,
        classes,
      }),
      createData({
        name: "cupcake",
        kills: 14,
        deaths: 7,
        assists: 4,
        adr: 83.4,
        kd: 0.78,
        headshots: 9,
        hs_perc: "64%",
        mvps: 2,
        three_kills: 0,
        four_kills: 0,
        classes,
      }),
      createData({
        name: "ginger",
        kills: 14,
        deaths: 7,
        assists: 4,
        adr: 83.4,
        kd: 0.78,
        headshots: 9,
        hs_perc: "64%",
        mvps: 2,
        three_kills: 0,
        four_kills: 0,
        classes,
      }),
    ];

    return (
      <div className={classes.homepageRoot}>
        <Paper className={classes.lobbyFrame}>
          {!user && <Typography>Please wait...</Typography>}

          {user && (
            <>
              <Typography>Team_chiarezza</Typography>
              <Scoreboard rows={rows} className={classes.scoreboard} />

              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />

              <Typography>Team_Hubert</Typography>
              <Scoreboard className={classes.scoreboard} rows={rows} />

              <div className={classes.controlCont}>
                <Button
                  to="/match/asdffasd"
                  component={Link}
                  variant="contained"
                >
                  Back to Match
                </Button>
              </div>

              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
              <div className={classes.vspacer10} />
            </>
          )}
        </Paper>
      </div>
    );
  }
}

MatchScoreboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(MatchScoreboardPage);
