import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 33,
    marginLeft: "auto",
    marginRight: "auto",
  },
  headline: {
    fontSize: "16pt",
    fontWeight: 525,
  },
  smallSubTitle: {
    fontSize: "12pt",
    fontWeight: 525,
  },
  para: {
    margin: "15px 8px",
  },
  "@media (min-width: 1024px)": {
    root: {
      width: 800,
    },
    para: {
      width: "85%",
      margin: "15px auto",
    },
  },
});

class AboutUsPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Typography component="h1" variant="h1" className={classes.headline}>
            About the people behind Ouro Matchmaking
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Welcome to Ouro, a matchmaking service designed by gamers, for
            gamers. At Ouro, we are dedicated to providing Counter-Strike
            players with a superior matchmaking experience that prioritizes
            fairness, integrity, and community-driven improvement. Our platform
            is built to create a gaming environment free from cheaters, trolls,
            and toxicity, ensuring that your time spent in the game is nothing
            short of enjoyable and competitive.
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro isn’t just another matchmaking service; it’s a space where the
            voice of the community shapes the experience. We believe that by
            listening to our players and actively incorporating their feedback,
            we can continually refine our service to meet the evolving needs of
            the CS community. Our goal is to cultivate a place where players can
            focus on what they love—playing CS in a fair, respectful, and
            engaging atmosphere.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            className={classes.smallSubTitle}
          >
            About Me: Chiarezza (Itamar Arjuan)
          </Typography>

          <Typography component={"p"} className={classes.para}>
            My name is Itamar Arjuan, also known as Chiarezza, and I am the mind
            behind Ouro. With over 15 years of experience in gaming and coding,
            and 2000 hours logged in Counter-Strike alone, I understand the
            frustrations that can arise from unfair play and toxic behavior.
            That’s why I created Ouro—a matchmaking service that embodies my
            commitment to delivering a better, more enjoyable experience for
            players who want to immerse themselves in quality gaming.
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro is the result of my passion for gaming and my dedication to
            creating a platform where the CS community can thrive. I believe in
            the power of a well-balanced, cheater-free environment and have
            poured my years of experience into building a system that puts
            players first.
          </Typography>

          <Typography
            component="h3"
            variant="h3"
            className={classes.smallSubTitle}
          >
            A Note from the Creator
          </Typography>

          <Typography component={"p"} className={classes.para}>
            Ouro is more than just a service; it’s the culmination of 2000 hours
            of playing CS and over 15 years of gaming and coding experience, all
            coming together to create a refined, fair, and fun place for
            players. I invite you to join our Discord server to share your
            thoughts, contribute ideas, and help us make Ouro an even better
            community for all. Let’s work together to make Counter-Strike a more
            comfortable and enjoyable space for everyone.
          </Typography>
        </Paper>
      </div>
    );
  }
}

AboutUsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutUsPage);
